<template>
  <b-tabs
    pills
    align="left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Général</span>
      </template>

      <b-card>
        <b-row>
          <b-col
            cols="12"
            xl="12"
            md="10"
          >
            <app-timeline>
              <app-timeline-item
                title="Nom et prenom du personnel"
                :subtitle="teacher.username + '  '+teacher.username"
                icon="UserIcon"
                variant="success"
              />
              <app-timeline-item
                title="CIN"
                :subtitle="teacher.cin"
                icon="GridIcon"
                variant="success"
              />
              <app-timeline-item
                title="Email"
                :subtitle="teacher.email"
                icon="MailIcon"
                variant="success"
              />
              <app-timeline-item
                title="Téléphone"
                :subtitle="teacher.phone"
                icon="PhoneIcon"
                variant="success"
              />
              <app-timeline-item
                title="Adresse"
                :subtitle="teacher.adress"
                icon="MapPinIcon"
                variant="warning"
              />
              <app-timeline-item
                title="Nature du contrat"
                :subtitle="teacher.natureCon"
                icon="InfoIcon"
                variant="success"
              />
            </app-timeline>
          </b-col>
        </b-row>
      </b-card>
    </b-tab>
    <!--/ general tab -->

    <!-- info -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserXIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Affectation Matiere</span>
      </template>

      <affectation />
    </b-tab>
  </b-tabs>
</template>

<script>
import axios from 'axios'
import {
  BTabs, BTab,
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
// import Account './Account.vue'
// import Attendance from './Attendance.vue'
import Affectation from './Affectation.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    AppTimeline,
    AppTimelineItem,
    BTabs,
    BTab,
    // Account,
    // Attendance,
    Affectation,
  },
  data() {
    return {
      options: {},
      teachers: [],
      teacher: '',
      id: '',
      is_superuser: '',
      is_staff: '',
      is_active: '',
      password: '',
      username: '',
    }
  },
  computed: {
    /* eslint-disable no-return-assign */
    /* eslint-disable no-underscore-dangle */
    /* eslint-disable consistent-return */
    // personnel() {
    //   // eslint-disable-next-line array-callback-return
    //   return this.employees.find(personnel => personnel.id === this.$route.params.id)
    // },
  },

  created() {
    this.getTeacher()
  },

  methods: {
    async getTeacher() {
      // @ts-ignore
      const { id } = this.$route.params
      await axios.get(`/api/authentication/users/detail/${id}`).then(response => {
        this.teacher = response.data
      })
    },
  },
}
</script>
